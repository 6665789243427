import create from "zustand";
import scorm from "../services/scorm";
import clientData from "../client-data";
import scenesData from "../scenes-data";
import appTextData from "../app-text-data";
import videoTranscriptData from "../video-transcript-data";

import env from "../env";
import { Scene } from "../types";

export type UserAnswerType = {
  sceneId: string;
  debriefIndex: number;
  score: number;
  answerIndex: number;
};

export type Client = {
  fonts?: { [key: string]: string };
  logo?: string;
  name?: string;
  primaryColor?: string;
  secondaryColor?: string;
};

export type VideoTranscript = {
  [lang: string]: string;
};

export type AppState = {
  scenes: Scene[];
  setScenes: (scenes: AppState["scenes"]) => void;
  appText: any;
  setAppText: (appText: any) => void;
  appTitle: string;
  setAppTitle: (appTitle: string) => void;
  videoTranscript: VideoTranscript;
  setVideoTranscript: (videoTranscript: VideoTranscript) => void;
  client: Client;
  setClient: (client: Client) => void;
  entering: boolean;
  setEntering: (entering: boolean) => void;
  language: string;
  setLanguage: (language: string) => void;
  languageSelected: boolean;
  setLanguageSelected: (languageSelected: boolean) => void;
  showSetting: boolean;
  setShowSetting: (showSetting: boolean) => void;
  sound: boolean;
  setSound: (sound: boolean) => void;
  captions: boolean;
  setCaptions: (captions: boolean) => void;
  showProgress: boolean;
  setShowProgress: (boolean: boolean) => void;
  browser: string;
  setBrowser: (browser: string) => void;
  projectID: string;
  setprojectID: (projectID: string) => void;
  userAnswers: UserAnswerType[];
  setUserAnswers: (answer: any) => void;
  debriefMode: boolean;
  setDebriefMode: (mode: boolean) => void;
  debriefId: string;
  setDebriefId: (debriefId: string) => void;
  debriefIndex: number;
  setDebriefIndex: (currentSectionIndex: number) => void;
};

export const useApp = create<AppState>((set) => {
  return {
    scenes: scenesData as unknown as Scene[],
    setScenes(scenes) {
      set({ scenes });
    },
    appText: appTextData,
    setAppText(appText) {
      set({ appText });
    },
    appTitle: "",
    setAppTitle(appTitle) {
      set({ appTitle });
    },
    videoTranscript: videoTranscriptData,
    setVideoTranscript(videoTranscript) {
      set({ videoTranscript });
    },
    client: clientData,
    setClient(client) {
      set({ client });
    },
    language: "en",
    setLanguage(language) {
      set({ language });
      if (scorm.isConnected()) {
        scorm.setData({ language: language });
      } else {
        localStorage.setItem(
          env.localStorage.language,
          JSON.stringify(language),
        );
      }
    },
    languageSelected: false,
    setLanguageSelected(languageSelected) {
      set({ languageSelected });
      if (scorm.isConnected()) {
        scorm.setData({ languageSelected: languageSelected });
      } else {
        localStorage.setItem(
          env.localStorage.languageSelected,
          JSON.stringify(languageSelected),
        );
      }
    },
    showSetting: false,
    setShowSetting(showSetting) {
      set({ showSetting });
    },
    sound: true,
    setSound(sound) {
      set({ sound });
    },
    captions: false,
    setCaptions(captions) {
      set({ captions });
    },
    entering: false,
    setEntering(entering) {
      set({ entering });
    },
    showProgress: false,
    setShowProgress(showProgress) {
      set({ showProgress });
    },
    browser: "",
    setBrowser(browser) {
      set({ browser });
    },
    projectID: "",
    setprojectID(projectID) {
      set({ projectID });
    },
    debriefMode: false,
    setDebriefMode(debriefMode) {
      set({ debriefMode: debriefMode });
    },
    debriefId: "",
    setDebriefId(debriefId) {
      set({ debriefId });
    },
    debriefIndex: 0,
    setDebriefIndex(debriefIndex) {
      set({ debriefIndex });
      if (scorm.isConnected()) {
        scorm.setData({ debriefIndex });
      } else {
        localStorage.setItem(
          env.localStorage.debriefIndex,
          JSON.stringify(debriefIndex),
        );
      }
    },
    userAnswers: [],
    setUserAnswers(userAnswer: UserAnswerType[]) {
      set({ userAnswers: userAnswer });
      if (scorm.isConnected()) {
        scorm.setData({ userAnswer });
      } else {
        localStorage.setItem(
          env.localStorage.userAnswer,
          JSON.stringify(userAnswer),
        );
      }
    },
  };
});
