import { scorm as api } from "@gamestdio/scorm";

type Data = null | {
  [prop: string]: any;
};

export enum Status {
  passed = "passed",
}

api.configure({
  debug: true,
  version: "1.2",
  handleCompletionStatus: false,
  handleExitMode: true,
});

const scorm = {
  init() {
    api.initialize();
    api.set("cmi.core.exit", "suspend");
    api.commit();
  },

  onConnected(fn: Function) {
    if (this.isConnected()) {
      fn();
    } else {
      requestAnimationFrame(() => this.onConnected(fn));
    }
  },

  isConnected(): boolean {
    return api.isActive;
  },

  getData(): Data {
    try {
      return JSON.parse(api.get("cmi.suspend_data") ?? "{}");
    } catch {
      return null;
    }
  },

  setData(data: Data) {
    const currentData = this.getData();
    api.set(
      "cmi.suspend_data",
      data === null ? "" : JSON.stringify({ ...currentData, ...data }),
    );
    api.commit();
  },

  getProgress(): number {
    const score = parseFloat(api.get("cmi.core.score.raw"));
    return isNaN(score) ? 0 : score; // 0 - 100
  },

  setProgress(progress: number) {
    api.set("cmi.core.score.raw", progress);
    api.commit();
  },

  setStatus(status: Status) {
    api.set("cmi.core.lesson_status", status);
    api.commit();
  },

  getStatus(): Status {
    return api.get("cmi.core.lesson_status") as Status;
  },

  complete() {
    console.log("scorm.complete");
    this.setProgress(100);
    this.setStatus(Status.passed);
    api.commit();
  },
};

export default scorm;
