import React from "react";
import { HtmlWrapper } from "./styles";

type Props = {
  html: any;
  style?: {};
  variant: number;
};

export default function HtmlContinueScreen({ html, style = {}, variant }: Props) {
  return (
    <HtmlWrapper style={style} variant={variant}>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </HtmlWrapper>
  );
}
