import styled, { css } from "styled-components";
import { BREAKPOINT_MOBILE } from "../../styles/breakpoints";
import vars from "../../styles/vars";
import { responsiveUnit } from "../../utils";

export const ImageWrapper = styled.figure`
  background-color: ${vars.colors.black};
  width: 100%;
  padding: 0.5rem;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  @media (max-width: ${BREAKPOINT_MOBILE}) {
    gap: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
    padding-top: 0.5rem;
  }
`;

type CollageWrapperProps = {
  variant: number;
};

export const CollageWrapper = styled.div<CollageWrapperProps>`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;

  @media (max-width: ${BREAKPOINT_MOBILE}) {
    height: 100%;
    min-height: 30vh;
  }

  @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 450px) {
    min-height: 50vh;
  }

  ${({ variant }) => {
    if (variant === 2) {
      return css`
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 10px;

        @media (max-width: ${BREAKPOINT_MOBILE}) {
          height: 100%;
          grid-template-columns: 100%;
          grid-template-rows: 50% 50%;
          gap: 5px;
        }

        @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
          grid-template-columns: 50% 50%;
          grid-template-rows: 100%;
          gap: 5px;
          min-height: 50vh;
        }
      `;
    }
  }};
`;

type CollageImageProps = {
  variant: number;
  gridArea?: string;
};

export const CollageImage = styled.img<CollageImageProps>`
  width: 100%;
  height: ${({ variant }) => (variant === 4 ? "100%" : "50vh")};
  outline-color: transparent;
  align-self: flex-start;
  grid-area: ${({ gridArea, variant }) => variant === 4 && gridArea};

  @media (max-width: ${BREAKPOINT_MOBILE}) {
    height: 100%;
    width: 100vw;
    height: 30vh;
  }

  @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
    height: 50vh;
  }

  &:focus {
    outline: none;
  }
`;

export const FieldSet = styled.fieldset<{ showDecisions: boolean }>`
  position: relative;
  border: none;
  padding: 0;
  margin: 0;
  opacity: ${({ showDecisions }) => (showDecisions ? "1" : "0")};
  visibility: ${({ showDecisions }) => (showDecisions ? "visible" : "hidden")};
  pointer-events: ${({ showDecisions }) => (showDecisions ? "all" : "none")};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: ${BREAKPOINT_MOBILE}) {
    gap: 0.5rem;
    justify-content: center;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}) {
    justify-content: space-evenly;
    gap: 0.5rem;
    margin-bottom: 2rem;
  }

  @media (max-height: 600px) and (max-width: ${BREAKPOINT_MOBILE}) {
    gap: 0;
  }

  @media (max-width: 220px) {
    margin-top: 2rem;
  }

  @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
    padding: 0rem;
    gap: 0.3rem;

    & div > legend > div {
      padding: 0.5rem;
    }
  }
`;

type DecisionProps = {
  variant?: number;
};

export const Decision = styled.button<DecisionProps>`
  ${responsiveUnit({ size: 26, prop: "font-size", ratio: 0.8 })}
  ${responsiveUnit({ size: 32, prop: "padding-top", ratio: 0.8 })}
  ${responsiveUnit({ size: 40, prop: "padding-bottom", ratio: 0.8 })}
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 400ms;
  font-weight: bold;
  color: ${vars.colors.white};
  opacity: 0.5;
  border-top: 1px solid #facc0a;

  ${({ variant }) => {
    if (variant === 1) {
      return css`
        width: 60%;
      `;
    }
    if (variant === 2) {
      return css`
        width: 40%;
      `;
    }
    if (variant === 3) {
      return css`
        width: 30%;
      `;
    }
    if (variant === 4) {
      return css`
        width: 20%;
      `;
    }
  }};

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline-color: transparent;
  }

  @media (max-width: 900px) {
    ${responsiveUnit({ size: 18, prop: "font-size", ratio: 0.8 })}
    ${responsiveUnit({ size: 20, prop: "padding", ratio: 0.8 })}
  }

  @media (max-height: 400px) {
    ${responsiveUnit({ size: 14, prop: "font-size", ratio: 0.8 })}
  }

  @media (max-width: ${BREAKPOINT_MOBILE}) {
    width: 100%;
  }
`;

export const AnswerWrapper = styled.span`
  width: 100%;
  ${responsiveUnit({
    size: 16,
    prop: "font-size",
    ratio: 0.8,
  })}
`;

export const Question = styled.div<{ showQuestion: boolean }>`
  pointer-events: ${({ showQuestion }) => (showQuestion ? "all" : "none")};
  color: #ffffff;
  ${responsiveUnit({ size: 20, prop: "padding", ratio: 0.8 })}
  text-align: center;
  width: 100%;
  opacity: 1;
  transform: ${({ showQuestion }) =>
    showQuestion ? "transform: translate(0, 0)" : "translate(0, -100%)"};
  transition: ${({ showQuestion }) =>
    showQuestion ? "transform 800ms" : " transform 400ms"};
  outline: none;

  h1 {
    ${responsiveUnit({ size: 28, prop: "font-size", ratio: 0.8 })}
    ${responsiveUnit({ size: 0, prop: "padding-top", ratio: 0.8 })}
    ${responsiveUnit({ size: 25, prop: "padding-right", ratio: 0.8 })}
    ${responsiveUnit({ size: 25, prop: "padding-bottom", ratio: 0.8 })}
    ${responsiveUnit({ size: 25, prop: "padding-left", ratio: 0.8 })}
    
    max-width: 1200px;
    margin: 0 auto;
    font-weight: normal;

    @media (max-width: 1000px) {
      ${responsiveUnit({ size: 22, prop: "font-size", ratio: 0.8 })}
      ${responsiveUnit({ size: 0, prop: "padding-top", ratio: 0.8 })}
      ${responsiveUnit({ size: 19, prop: "padding-right", ratio: 0.8 })}
      ${responsiveUnit({ size: 19, prop: "padding-bottom", ratio: 0.8 })}
      ${responsiveUnit({ size: 19, prop: "padding-left", ratio: 0.8 })}
    }

    @media (max-width: 700px) {
      ${responsiveUnit({ size: 18, prop: "font-size", ratio: 0.8 })}
      ${responsiveUnit({ size: 0, prop: "padding-top", ratio: 0.8 })}
      ${responsiveUnit({ size: 10, prop: "padding-right", ratio: 0.8 })}
      ${responsiveUnit({ size: 10, prop: "padding-bottom", ratio: 0.8 })}
      ${responsiveUnit({ size: 10, prop: "padding-left", ratio: 0.8 })}
    }
  }

  @media (max-height: 600px) and (max-width: ${BREAKPOINT_MOBILE}) {
    padding: 0.3rem;
  }

  &:focus {
    outline-color: black;
  }
`;

type DecisionsProps = {
  showDecisions: boolean;
};

export const Decisions = styled.div<DecisionsProps>`
  pointer-events: ${({ showDecisions }) => (showDecisions ? "all" : "none")};
  background-color: #000;
  width: 100%;
  transform: ${({ showDecisions }) =>
    showDecisions ? "transform: translate(0, 0)" : "translate(0, 100%)"};
  transition: ${({ showDecisions }) =>
    showDecisions ? "transform 800ms" : " transform 400ms"};
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  &:not(:hover) {
    ${Decision} {
      opacity: 1 !important;
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE}) {
    flex-direction: column;
    place-items: center;
  }
`;
