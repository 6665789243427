import React from "react";
import Button from "../button/button";
import { DynamicLink as DynamicLinkType } from "../../types";
import { useApp } from "../../state/app";

type Props = {
  dynamicLinks: DynamicLinkType[];
  startButtonBackground: string;
  startButtonColor?: string;
};

const EMPTY_ARRAY: DynamicLinkType[] = [];

export default function DynamicLink({
  dynamicLinks = EMPTY_ARRAY,
  startButtonBackground,
  startButtonColor = "#000",
}: Props) {
  const language = useApp((state) => state.language);
  const scenes = useApp((state) => state.scenes);
  const userAnswers = useApp((state) => state.userAnswers);

  const dynamicLink = React.useMemo<DynamicLinkType | null>(() => {
    for (const scene of scenes) {
      if (scene === null) continue;
      for (const dynamicLink of dynamicLinks) {
        if (dynamicLink.sceneId !== scene.sceneId) continue;
        for (const userAnswer of userAnswers) {
          if (
            userAnswer.sceneId === dynamicLink.sceneId &&
            userAnswer.answerIndex === dynamicLink.answerIndex
          ) {
            return dynamicLink;
          }
        }
      }
    }
    return null;
  }, [scenes, dynamicLinks, userAnswers]);

  if (dynamicLink === null) return null;

  return (
    <Button
      backgroundColor={startButtonBackground}
      color={startButtonColor}
      link={dynamicLink.sceneLinkId}
    >
      {dynamicLink?.text?.[language] ?? "Start"}
    </Button>
  );
}
