import styled from "styled-components";
import vars from "../../styles/vars";
import { responsiveUnit } from "../../utils";

export const HtmlWrapper = styled.div`
  margin: 0;
  padding: 0;
  color: var(--color-primary);
  font-size: 2em;
  font-weight: bold;
  font-family: ${vars.fontSecondary};

  @media (max-width: 900px) {
    ${responsiveUnit({ size: 30, prop: "font-size", ratio: 0.8 })}
  }

  & p {
    margin-right: 10px;
  }
`;
