import styled, { css, keyframes } from "styled-components";
import { BREAKPOINT_MOBILE } from "../../styles/breakpoints";
import vars from "../../styles/vars";

export const PageWrapper = styled.div`
  background-color: ${vars.colors.black};
  width: 100%;
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding: 1rem;

  @media (max-width: 650px) {
    padding: 0.5rem;
  }

  @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
    padding: 0.5rem;
  }
`;

type CollageWrapperProps = {
  variant: number;
};

export const ImageCollageWrapper = styled.div<CollageWrapperProps>`
  width: 100%;
  height: 100%;
  display: grid;
  justify-items: center;
  gap: 10px;
  overflow: hidden;

  @media (max-width: 650px) {
    gap: 5px;
  }

  ${({ variant }) => {
    if (variant === 2) {
      return css`
        grid-template-columns: 50% 50%;

        @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
          grid-template-columns: 100%;
          grid-template-rows: 49% 50%;
        }

        @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
          grid-template-columns: 49% 50%;
          grid-template-rows: 100%;
        }
      `;
    }
    if (variant === 3) {
      return css`
        grid-template-columns: repeat(2, 50% - 10px);
        grid-template-rows: 50% 50%;
        grid-template-areas:
          "i1 i2"
          "i1 i3";

        @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
          grid-template-columns: 100%;
          grid-template-rows: repeat(4, 33%);
          grid-template-areas:
            "i1"
            "i2"
            "i3";
        }
      `;
    }
    if (variant === 4) {
      return css`
        grid-template-columns: repeat(2, 50% - 10px);
        grid-template-rows: repeat(2, 50%);
        grid-template-areas:
          "i1 i2"
          "i1 i3";

        @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
          grid-template-columns: 100%;
          grid-template-rows: repeat(4, calc(98% / 4));
          grid-template-areas:
            "i1"
            "i2"
            "i3";
        }
      `;
    }
    if (variant === 5) {
      return css`
        grid-template-columns: repeat(2, 50% - 10px);
        grid-template-rows: repeat(6, 100% / 6);
        grid-template-areas:
          "i1 i3"
          "i1 i3"
          "i1 i4"
          "i2 i4"
          "i2 i5"
          "i2 i5";

        @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
          grid-template-columns: 100%;
          grid-template-rows: repeat(5, calc(98% / 5));
          grid-template-areas:
            "i1"
            "i2"
            "i3"
            "i4"
            "i5";
        }
      `;
    }
  }};
`;

type ImageWrapperProps = {
  variant: number;
  src: string;
  gridArea?: string;
  animate?: boolean;
  delay?: number;
};

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const ImageWrapper = styled.div<ImageWrapperProps>`
  background-image: ${({ src }) => src && `url(${src})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0;

  ${({ variant, gridArea }) => {
    if (variant === 3 || variant === 5) {
      return css`
        grid-area: ${gridArea};
      `;
    }
  }};

  ${({ animate, delay }) => {
    if (animate)
      return css`
        animation: ${fadeIn} 1000ms ease-in-out forwards;
        animation-delay: ${`${delay}ms`};
      `;
  }}
`;
