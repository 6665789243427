import React from "react";
import { Answer, SceneDataProps } from "../types";
import { useHistory } from "react-router-dom";
import { useApp, UserAnswerType } from "../state/app";
import { MODE } from "../config/consts";
import { updateUserAnswers } from "../utils";

export default function useVideoControls(
  forceSubtitles?: boolean | { [locale: string]: boolean },
  answersShow?: string,
  answers?: Answer[],
  videoSrc?: string,
  isOn?: boolean,
) {
  const history = useHistory();
  const {
    sound,
    captions,
    language,
    setSound,
    setCaptions,
    setShowProgress,
    projectID,
    setUserAnswers,
    debriefMode,
    debriefId,
  } = useApp();
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const timeout = React.useRef<NodeJS.Timeout | null>(null);
  const [playing, setPlaying] = React.useState(true);
  const [muted, setMuted] = React.useState(false);
  const [caption, setCaption] = React.useState(true);
  const [videoEnded, setVideoEnded] = React.useState(false);
  const [showDecisions, setShowDecisions] = React.useState(false);
  const [playAnimation, setPlayAnimation] = React.useState(false);

  const togglePlay = React.useCallback((e: React.SyntheticEvent) => {
    setPlaying((playing) => {
      if (playing) {
        videoRef.current?.pause();
      } else {
        videoRef.current?.play();
      }
      return !playing;
    });
    setPlayAnimation(true);
  }, []);

  const toggleMute = React.useCallback(() => {
    if (muted && videoRef.current) {
      videoRef.current.muted = false;
      setMuted(false);
      setSound(true);
    } else if (!muted && videoRef.current) {
      videoRef.current.muted = true;
      setMuted(true);
      setSound(false);
    }
  }, [muted, setSound]);

  const forceSubtitlesToShow = React.useMemo(() => {
    return typeof forceSubtitles === "object"
      ? forceSubtitles[language]
      : forceSubtitles;
  }, [forceSubtitles, language]);

  const toggleCaption = React.useCallback(() => {
    if (caption && videoRef.current && videoRef.current.textTracks[0]) {
      if (forceSubtitlesToShow) {
        videoRef.current.textTracks[0].mode = "showing";
        setCaption(true);
        setCaptions(true);
      } else {
        videoRef.current.textTracks[0].mode = "hidden";
        setCaption(false);
        setCaptions(false);
      }
    } else if (!caption && videoRef.current && videoRef.current.textTracks[0]) {
      videoRef.current.textTracks[0].mode = "showing";
      setCaption(true);
      setCaptions(true);
    }
  }, [caption, setCaptions, forceSubtitlesToShow]);

  const rewind = React.useCallback(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = videoRef.current?.currentTime - 10;
    }
  }, []);

  const fastForward = React.useCallback(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = videoRef.current?.currentTime + 10;
    }
  }, []);

  const handleEnded = React.useCallback(() => {
    if (answers && answers.length === 1) {
      history.push(
        MODE.startsWith("scorm") || MODE.startsWith("web")
          ? `/scene/${answers[0].sceneId}`
          : `/${projectID}/scene/${answers[0].sceneId}`,
      );
      setVideoEnded(true);
    }
  }, [answers, history, projectID]);

  const handleDecisionSelected = React.useCallback(
    (sceneData: SceneDataProps) => {
      const { debriefIndex } = useApp.getState();
        // @todo find the score and pass it to answer object
        const answer: UserAnswerType = {
          sceneId: sceneData.sceneId,
          answerIndex: sceneData.answerIndex,
          debriefIndex: debriefIndex,
          score: 0,
        };
      if (debriefMode && debriefId) {
        history.push(
          MODE.startsWith("scorm") || MODE.startsWith("web")
            ? `/scene/${debriefId}`
            : `/${projectID}/scene/${debriefId}`,
        );
        updateUserAnswers(answer);
        // userAnswer[sceneData.sceneId] = sceneData.text;
      } else {
        history.push(
          MODE.startsWith("scorm") || MODE.startsWith("web")
            ? `/scene/${sceneData.answerSceneId}`
            : `/${projectID}/scene/${sceneData.answerSceneId}`,
        );
        // userAnswer[sceneData.sceneId] = sceneData.text;
        updateUserAnswers(answer);
      }
    },
    [history, setUserAnswers, debriefId, debriefMode, projectID],
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      const showTime = parseFloat(answersShow ?? "1");
      if (videoRef.current && videoRef.current.readyState) {
        if (
          videoRef.current?.currentTime >=
          videoRef.current?.duration * showTime
        ) {
          if (answers && answers.length > 1) {
            setShowDecisions(true);
            setVideoEnded(true);
          }
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [
    answers,
    history,
    projectID,
    debriefId,
    debriefMode,
    answersShow,
    language,
  ]);

  React.useEffect(() => {
    setMuted(!sound);
    if (forceSubtitlesToShow) {
      setCaption(true);
      setCaptions(true);
    } else {
      setCaption(captions);
    }
  }, [sound, captions, forceSubtitlesToShow, setCaptions]);

  React.useEffect(() => {
    if (answers && answers.length > 2) {
      setShowProgress(true);
      timeout.current = setTimeout(() => {
        setShowProgress(false);
      }, 10000);

      return () => {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
      };
    }
  }, [answers, setShowProgress]);

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.textTracks[0].mode = forceSubtitlesToShow
        ? "showing"
        : captions
        ? "showing"
        : "hidden";
    }
  }, [captions, forceSubtitlesToShow]);

  React.useEffect(() => {
    if (videoRef.current) {
      setMuted(!sound);
      if (forceSubtitlesToShow) {
        setCaption(true);
      } else {
        setCaption(captions);
      }
      if (!videoSrc) return;
      const promise = videoRef.current.play();
      if (promise !== undefined) {
        promise
          .then((_) => {
            setPlaying(true);
          })
          .catch((error) => {
            console.error(error);
            // videoRef.current?.pause();
            // setPlaying(false);
          });
      }
    }
  }, [captions, forceSubtitlesToShow, sound, videoSrc]);

  React.useEffect(() => {
    function keyboardShortCut(e: any) {
      if (videoRef.current) {
        if (e.key === "k") {
          videoRef.current.currentTime = videoRef.current.duration;
        }
        // check if user has pressed space bar
        if (e.keyCode === 32) {
          if (document.activeElement) {
            const attribute = document.activeElement.getAttribute("data-item");
            if (attribute === "mp4") {
              !videoEnded && togglePlay(e);
              e.stopPropagation();
            } else {
              e.preventDefault();
              e.stopPropagation();
            }
          }
        }
      }
    }

    window.addEventListener("keypress", keyboardShortCut);
    return () => window.removeEventListener("keypress", keyboardShortCut);
  }, [togglePlay, videoEnded, isOn]);

  return {
    videoRef,
    muted,
    togglePlay,
    handleEnded,
    videoEnded,
    language,
    playing,
    playAnimation,
    showDecisions,
    fastForward,
    toggleCaption,
    handleDecisionSelected,
    caption,
    toggleMute,
    rewind,
    setPlaying,
  };
}
