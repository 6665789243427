import React from "react";
import {
  IntroContainer,
  IntroLayoutWrapper,
  ItemsContainer,
  IntroImage,
  ImageWrapper,
  IntroTextWrapper,
  LogoWrapper,
  LogoContainer,
  LogoBox,
  TextBoxWrapper,
  CopyWrapper,
} from "./styles";
import HtmlIntro from "./HtmlIntro/HtmlIntro";
import Button from "../button/button";
import { useApp } from "../../state/app";
import { Answer, ImageProps, LocaleValue } from "../../types";
import Animate from "../animate/animate";
import Scene from "../scene/scene";
import { MODE } from "../../config/consts";
import scorm from "../../services/scorm";
import vars from "../../styles/vars";

type Props = {
  image1: string;
  image2: string;
  image3: string;
  title: LocaleValue;
  primaryColor: string;
  header: LocaleValue;
  description: LocaleValue;
  startButtonText: string;
  startButtonBackground: string;
  backgroundColor: string;
  startButtonColor?: string;
  companyLogo: ImageProps;
  answers: Answer[];
  lastScene?: boolean;
};

export default function IntroLayout({
  image1,
  image2,
  image3,
  title,
  backgroundColor = "#000",
  header,
  description,
  startButtonBackground,
  startButtonColor = "#000",
  companyLogo,
  answers,
  lastScene = false,
}: Props) {
  const { browser, language, setAppTitle } = useApp();

  React.useEffect(() => {
    setAppTitle(title?.[language]);
  }, [title, language]);

  React.useMemo(() => {
    if (!title) return;
    document.title = title?.[language] ?? "";
  }, [title, language]);

  const focusRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    focusRef.current?.focus();
    localStorage.removeItem("e-learning-debriefIndex");
    localStorage.removeItem("e-learning-userAnswer");
  }, []);

  React.useEffect(() => {
    if (!MODE.startsWith("scorm")) return;
    if (!scorm.isConnected()) return;
    if (lastScene) {
      scorm.complete();
    }
  }, [lastScene]);

  return (
    <Scene>
      <IntroLayoutWrapper
        style={{
          backgroundColor,
        }}
      >
        <Animate />
        <IntroContainer browser={browser}>
          <ItemsContainer>
            <ImageWrapper>
              <IntroImage backgroundImage={image1} grid="img1" />
              <IntroImage backgroundImage={image2} grid="img2" />
              <IntroImage backgroundImage={image3} grid="img3" />
            </ImageWrapper>
            <IntroTextWrapper>
              <LogoWrapper>
                {" "}
                {companyLogo?.src && (
                  <LogoContainer>
                    <LogoBox>
                      <img src={companyLogo.src} alt={companyLogo?.alt} />
                    </LogoBox>
                  </LogoContainer>
                )}
                <TextBoxWrapper
                  dangerouslySetInnerHTML={{ __html: header?.[language] }}
                  style={{ fontFamily: `${vars.fontPrimary}` }}
                />
              </LogoWrapper>
              <CopyWrapper>
                <HtmlIntro
                  html={description?.[language]}
                  style={{
                    color: "#fff",
                    alignSelf: "flex-start",
                    fontFamily: `${vars.fontTertiary}`,
                  }}
                />
                {answers.map((answer, index) => {
                  return (
                    <Button
                      backgroundColor={startButtonBackground}
                      color={startButtonColor}
                      link={answer.sceneId}
                      key={index}
                    >
                      {answer?.text?.[language] ?? "Start"}
                    </Button>
                  );
                })}
              </CopyWrapper>
            </IntroTextWrapper>
          </ItemsContainer>
        </IntroContainer>
      </IntroLayoutWrapper>
    </Scene>
  );
}
