import React from "react";
import { LoaderWrapper, Spinner, Text } from "./styles";
import Animate from "../animate/animate";
import { useApp } from "../../state/app";

export default function Loader() {
  const { appTitle } = useApp();

  React.useMemo(() => {
    document.title = appTitle ?? "Loader";
  }, [appTitle]);

  return (
    <LoaderWrapper>
      <Animate />
      <Spinner>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 160 160"
          width="160"
          height="160"
          preserveAspectRatio="xMidYMid meet"
        >
          <defs>
            <clipPath id="__lottie_element_2">
              <rect width="160" height="160" x="0" y="0" />
            </clipPath>
          </defs>
          <g clipPath="url(#__lottie_element_2)">
            <g transform="matrix(1,0,0,1,0,0)" opacity="0.4">
              <g opacity="1" transform="matrix(1,0,0,1,80,80)">
                <path
                  fill="rgb(255,255,255)"
                  fillOpacity="1"
                  d=" M0,80 C-44.11199951171875,80 -80,44.11199951171875 -80,0 C-80,-44.11199951171875 -44.11199951171875,-80 0,-80 C44.112998962402344,-80 80,-44.11199951171875 80,0 C80,44.11199951171875 44.112998962402344,80 0,80z M0,-40 C-22.055999755859375,-40 -40,-22.055999755859375 -40,0 C-40,22.055999755859375 -22.055999755859375,40 0,40 C22.055999755859375,40 40,22.055999755859375 40,0 C40,-22.055999755859375 22.055999755859375,-40 0,-40z"
                />
              </g>
            </g>
            <g transform="matrix(1,0,0,1,0,0)" opacity="1">
              <g opacity="1" transform="matrix(1,0,0,1,79.9990005493164,20)">
                <path
                  fill="rgb(255,255,255)"
                  fillOpacity="1"
                  d=" M20,0 C20,11.045999526977539 11.045999526977539,20 0,20 C-11.045000076293945,20 -20,11.045999526977539 -20,0 C-20,-11.045000076293945 -11.045000076293945,-20 0,-20 C11.045999526977539,-20 20,-11.045000076293945 20,0z"
                />
              </g>
            </g>
          </g>
        </svg>
      </Spinner>
      <Text>Loading...</Text>
    </LoaderWrapper>
  );
}
