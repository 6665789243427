import React from "react";
import { Answer, LocaleValue } from "../../types";
import { MODE } from "../../config/consts";
import scorm from "../../services/scorm";
import {
  AnswerWrapper,
  Decision,
  Decisions,
  FieldSet,
  ImageWrapper,
  CollageWrapper,
  Question,
  CollageImage,
} from "./styles";
import { useApp, UserAnswerType } from "../../state/app";
import { useHistory } from "react-router-dom";
import { updateUserAnswers } from "../../utils";

type Props = {
  sceneId: string;
  image1: string;
  image2: string;
  answers?: Answer[];
  answersTime?: number;
  answersShow?: string;
  countDownColor?: string;
  question?: LocaleValue;
  lastScene?: boolean;
};

export default function Image({
  sceneId,
  image1,
  image2,
  answers,
  question,
  lastScene = false,
}: Props) {
  React.useEffect(() => {
    if (!MODE.startsWith("scorm")) return;
    if (!scorm.isConnected()) return;
    if (lastScene) {
      scorm.complete();
    }
  }, [lastScene]);

  const showSetting = useApp((state) => state.showSetting);
  const language = useApp((state) => state.language);
  const projectID = useApp((state) => state.projectID);
  const debriefMode = useApp((state) => state.debriefMode);
  const debriefId = useApp((state) => state.debriefId);

  const { appTitle } = useApp();

  React.useMemo(() => {
    document.title = appTitle ?? "Question";
  }, [appTitle]);

  const history = useHistory();

  const questionRef = React.useRef<HTMLFieldSetElement>(null);

  const imagesArr = [image1, image2];

  let images: string[] = [];

  imagesArr.forEach((image) => image !== "" && images.push(image));

  const variant = images?.length ?? 1;

  return (
    <ImageWrapper>
      {/* div around parent element to fix a issue with safari not especting height: 100% on img element*/}
      <div style={{width: "100%"}}>
      <CollageWrapper variant={variant}>
        {images.map((image, index) => (
          <CollageImage
            key={image}
            src={image}
            alt=""
            gridArea={`i${index + 1}`}
            variant={variant}
          />
        ))}
      </CollageWrapper>
      </div>
      <FieldSet
        showDecisions={true}
        aria-hidden={showSetting}
        tabIndex={!showSetting ? 0 : -1}
        ref={questionRef}
      >
        {question?.[language] && (
          <div>
          <legend>
            <Question
              aria-hidden={showSetting}
              showQuestion={!!question?.[language]}
            >
              <h1 tabIndex={-1}>{question?.[language]}</h1>
            </Question>
          </legend>
          </div>
        )}
        <Decisions showDecisions={true}>
          {answers &&
            answers.length > 0 &&
            answers.map((answer, answerIndex) => {
              const text = answer?.text?.[language] ?? "";
              const progres = answer?.progres ?? 0.5;
              return (
                <Decision
                  tabIndex={!showSetting ? 0 : -1}
                  aria-hidden={showSetting}
                  variant={answers.length}
                  onClick={() => {
                    const sceneData = {
                      sceneId,
                      answerSceneId: answer.sceneId,
                      text,
                    };
                    const { debriefIndex } = useApp.getState();
                    const newAnswer: UserAnswerType = {
                      sceneId: sceneData.sceneId,
                      answerIndex,
                      debriefIndex: debriefIndex,
                      score: progres,
                    };
                    if (debriefMode && debriefId) {
                      history.push(
                        MODE.startsWith("scorm") || MODE.startsWith("web")
                          ? `/scene/${debriefId}`
                          : `/${projectID}/scene/${debriefId}`,
                      );
                      updateUserAnswers(newAnswer);
                    } else {
                      history.push(
                        MODE.startsWith("scorm") || MODE.startsWith("web")
                          ? `/scene/${sceneData.answerSceneId}`
                          : `/${projectID}/scene/${sceneData.answerSceneId}`,
                      );
                      updateUserAnswers(newAnswer);
                    }
                  }}
                  key={answerIndex}
                >
                  <AnswerWrapper>{text}</AnswerWrapper>
                </Decision>
              );
            })}
        </Decisions>
      </FieldSet>
    </ImageWrapper>
  );
}
