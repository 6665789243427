import React from "react";
import { Answer, LocaleValue } from "../../types";
import {
  PageWrapper,
  ImageCollageWrapper,
  ImageWrapper,
} from "./styles";
import { MODE } from "../../config/consts";
import scorm from "../../services/scorm";
import { useApp } from "../../state/app";
import { useHistory } from "react-router-dom";

type Props = {
  sceneId: string;
  image1: string;
  image2: string;
  image3: string;
  image4: string;
  image5: string;
  answers: Answer[];
  countDownColor?: string;
  lastScene?: boolean;
};

export default function ImageCollage({
  image1,
  image2,
  image3,
  image4,
  image5,
  answers,
  lastScene = false,
}: Props) {
  React.useEffect(() => {
    if (!MODE.startsWith("scorm")) return;
    if (!scorm.isConnected()) return;
    if (lastScene) {
      scorm.complete();
    }
  }, [lastScene]);

  const projectID = useApp((state) => state.projectID);

  const { appTitle } = useApp();

  React.useMemo(() => {
    document.title = appTitle ?? "Transition Screen";
  }, [appTitle]);

  const history = useHistory();

  const imagesArr = [image1, image2, image3, image4, image5];

  let images: string[] = [];

  imagesArr.forEach((image) => image !== "" && images.push(image));

  const variant = images?.length ?? 1;

  React.useEffect(() => {
    let timer: any;
    const changeScene = () => {
      timer = setTimeout(() => {
        if (!answers?.[0]?.sceneId) return;
        history.push(
          MODE.startsWith("scorm") || MODE.startsWith("web")
            ? `/scene/${answers?.[0].sceneId}`
            : `/${projectID}/scene/${answers?.[0].sceneId}`,
        );
      }, 4000);
    };
    changeScene();
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <PageWrapper>
      <ImageCollageWrapper variant={variant}>
        {images.map((image, index) => (
            <ImageWrapper
              key={image}
              src={image}
              variant={variant}
              gridArea={`i${index + 1}`}
              animate
              delay={(index + 1) * 500}
            />
        ))}
      </ImageCollageWrapper>
    </PageWrapper>
  );
}
