import styled from "styled-components";
import vars from "../../styles/vars";
import { BREAKPOINT_MOBILE } from "../../styles/breakpoints";

export const LineOfBusinessWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 70% 30%;
  justify-content: center;
  background-color: rgba(000, 000, 000);

  @media (max-width: ${BREAKPOINT_MOBILE}) {
    grid-template-columns: 100%;
    grid-template-rows: 40% 60%;
  }

  & > div {
    @media (max-width: ${BREAKPOINT_MOBILE}) {
      margin: 1rem;
      width: 90%;
    }
    img {
      height: 100%;
      object-fit: contain;
      padding: 1rem;
    }
  }
`;

export const ContentWrapper = styled.section`
  width: 100%;
  color: ${vars.colors.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;
  gap: 1.5rem;

  & img {
    align-self: flex-start;
    width: 6rem;

    @media (max-width: ${BREAKPOINT_MOBILE}) {
      width: 3rem;
    }
  }

  & > div {
    width: 100%;

    @media (max-width: ${BREAKPOINT_MOBILE}) {
      width: 120%;
    }
  }

  h1 {
    font-size: 1.2em !important;
    font-family: Primary, Arial, sans-serif;
    margin-bottom: 1em;
  }

  h2,
  h3 {
    font-size: 1.6em;
  }

  @media (max-width: 700px) {
    h1,
    h2 {
      font-size: 1.2em;
    }
  }
`;

type ImageWrapperProps = {
  backgroundImage?: string;
};

export const ImageWrapper = styled.div<ImageWrapperProps>`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
`;
