import React from "react";
import { HtmlWrapper } from "./styles";

type Props = {
  html: any;
  style?: {};
};

export default function Html({ html, style = {} }: Props) {
  return (
    <HtmlWrapper style={style}>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </HtmlWrapper>
  );
}