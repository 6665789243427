import styled from "styled-components";

export const HtmlWrapper = styled.div`
  margin: 0;
  padding: 0;
  font-size: 1em;
  overflow-y: hidden;

  & p {
    padding-bottom: 1rem;
  }
`;