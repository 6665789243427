import React from "react";
import { Answer, ImageProps, LocaleValue, Scene } from "../../types";
import { MODE } from "../../config/consts";
import scorm from "../../services/scorm";
import {
  MiddleDebriefWrapper,
  BannerImage,
  BannerText,
  SceneDebriefWrapper,
  ScoreContainer,
} from "./styles";
import HtmlHeader from "../htmlHeader/htmlHeader";
import vars from "../../styles/vars";
import useAppLogic from "../../hooks/use-app-logic";
import { useApp } from "../../state/app";
import { UserAnswerType } from "../../state/app";
import { SceneDebrief } from "./middle-debrief-container";
import Button from "../button/button";
import Html from "../html/html";
import HtmlDebrief from "../htmlDebrief/htmlDebrief";
import env from "../../env";

type Props = {
  sceneId: string;
  image: ImageProps;
  answersShow?: string;
  nextSectionText?: LocaleValue;
  scoreSummary?: LocaleValue;
  summary?: LocaleValue;
  showScore: boolean;
  lastScene?: boolean;
  answers: Answer[];
};

export default function MiddleDebrief({
  sceneId,
  image,
  nextSectionText,
  scoreSummary,
  summary,
  showScore = false,
  lastScene = false,
  answers,
}: Props) {
  React.useEffect(() => {
    if (!MODE.startsWith("scorm")) return;
    if (!scorm.isConnected()) return;
    if (lastScene) {
      scorm.complete();
    }
  }, [lastScene]);

  const { scenes, language, appText } = useAppLogic();
  const [userAnswers, setUserAnswers] = React.useState<UserAnswerType[]>();
  const [scenesData, setScenesData] = React.useState<Scene[]>([]);
  const [userScore, setUserScore] = React.useState(0);

  const { appTitle } = useApp();

  React.useMemo(() => {
    document.title = appTitle ?? "Debrief";
  }, [appTitle]);

  const debriefIndex = React.useMemo(() => {
    const debriefScenes = scenes.filter((scene) => {
      return scene?.componentId === "middleDebrief";
    });
    return debriefScenes.findIndex((item) => item?.sceneId === sceneId);
  }, [scenes, sceneId]);

  React.useEffect(() => {
    useApp.getState().setDebriefIndex(debriefIndex + 1);
  }, [debriefIndex]);

  React.useEffect(() => {
    console.log({ debriefIndex });
  }, [debriefIndex]);

  React.useEffect(() => {
    if (scorm.isConnected()) {
      const { userAnswer } = scorm.getData() ?? {};
      if (userAnswer) setUserAnswers(userAnswer);
    } else {
      const userAnswers = localStorage.getItem(env.localStorage.userAnswer);
      if (userAnswers) setUserAnswers(JSON.parse(userAnswers));
    }
  }, []);

  React.useEffect(() => {
    if (!userAnswers) return;
    const debriefScenes = getAllScenesForCurrentDebrief(userAnswers);
    const data: Scene[] = [];
    debriefScenes.forEach((debriefScene) => {
      const sceneData = scenes.find(
        (scene) => scene?.sceneId === debriefScene.sceneId,
      );
      if (sceneData) data.push(sceneData);
    });
    const newData = data.filter((scene) => !scene?.props.excludeFromDebrief);
    setScenesData(newData);
  }, [userAnswers]);

  const getAllScenesForCurrentDebrief = (answers: UserAnswerType[]) => {
    return answers.filter((answer) => answer.debriefIndex === debriefIndex);
  };

  React.useEffect(() => {
    let scores: number[] = [];
    userAnswers?.forEach((answer) => scores.push(answer.score));
    const userTotal = scores.reduce((acc, value) => acc + value, 0);
    const score = Number(((userTotal / scores.length) * 100).toFixed(2));
    setUserScore(score);
  }, [userAnswers]);

  return (
    <MiddleDebriefWrapper>
      <BannerImage src={image?.src} />
      <BannerText>
        <HtmlHeader
          html={summary?.[language]}
          style={{ fontFamily: `${vars.fontPrimary}`, color: "white" }}
        />
      </BannerText>
      <SceneDebriefWrapper>
        {scenesData?.length !== 0 &&
          scenesData.map((scene, index) => {
            let headline = "";
            if (scene?.props?.debriefHeader?.[language]) {
              headline = scene?.props?.debriefHeader?.[language];
            }
            const userScore = userAnswers?.find(
              (answer) => answer.sceneId === scene?.sceneId,
            )?.score;
            const userAnswer =
              scene?.props?.answers.find(
                (answer) => answer.progres === userScore,
              )?.debriefText?.[language] ?? "";
            const sceneId = scene?.sceneId ?? "";
            return (
              <SceneDebrief
                key={scene?.sceneId}
                image={scene?.props?.image1}
                userAnswer={userAnswer}
                headline={headline}
                sceneId={sceneId}
                index={index}
              />
            );
          })}
      </SceneDebriefWrapper>
      <ScoreContainer>
        {showScore ? (
          <>
            <div
              style={{ fontFamily: `${vars.fontSecondary}`, display: "flex" }}
            >
              <HtmlDebrief
                html={scoreSummary?.[language]}
                style={{
                  fontFamily: `${vars.fontSecondary}`,
                  marginRight: "1px",
                }}
              />
              <span>({userScore}%)</span>
            </div>
            {/* <p>
            Thanks for taking the time to complete this module, you scored (
            {userScore}%) overall.
          </p> */}
          </>
        ) : (
          <Html
            html={nextSectionText?.[language]}
            style={{ fontFamily: `${vars.fontSecondary}` }}
          />
        )}
        <Button
          backgroundColor={"white"}
          color={"black"}
          style={{
            border: `1px solid var(--color-primary)`,
            fontFamily: `${vars.fontTertiary}`,
            padding: "0.3em 1.3em ",
          }}
          link={answers?.[0].sceneId}
        >
          {appText?.continue?.[language] ?? "Continue"}
        </Button>
      </ScoreContainer>
    </MiddleDebriefWrapper>
  );
}
