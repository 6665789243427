import React from "react";
import { ContentWrapper, ImageWrapper, LineOfBusinessWrapper } from "./styles";
import Animate from "../animate/animate";
import Scene from "../scene/scene";
import { MODE } from "../../config/consts";
import scorm from "../../services/scorm";
import useAppLogic from "../../hooks/use-app-logic";
import { ImageProps, LocaleValue } from "../../types";
import Locale from "../locale/locale";

type OptionProps = { text: LocaleValue; sceneId: string; sceneName: string };

type Props = {
  title: LocaleValue;
  description?: LocaleValue;
  lastScene?: boolean;
  image?: ImageProps;
  options: OptionProps[];
};

export default function LineOfBusiness({
  title,
  lastScene = false,
  image,
  options,
}: Props) {
  const wrapper = React.useRef<HTMLDivElement>(null);
  const { language, langOptions } = useAppLogic();

  const nextScene = options?.[0].sceneId;

  React.useMemo(() => {
    document.title = title?.[language] ?? "Select language...";
  }, []);

  React.useEffect(() => {
    if (!MODE.startsWith("scorm")) return;
    if (!scorm.isConnected()) return;
    if (lastScene) {
      scorm.complete();
    }
  }, [lastScene]);

  React.useEffect(() => {
    wrapper.current?.focus();
  }, []);

  return (
    <Scene>
      <Animate />
      <LineOfBusinessWrapper ref={wrapper}>
        <ImageWrapper backgroundImage={image?.src}></ImageWrapper>
        <ContentWrapper>
          <img src="./assets/globe-icon.svg" alt="" />
          <Locale locales={langOptions} nextScene={nextScene}/>
        </ContentWrapper>
      </LineOfBusinessWrapper>
    </Scene>
  );
}
