import styled from "styled-components";
import { BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from "../../styles/breakpoints";
import vars from "../../styles/vars";

export const LocaleWrapper = styled.main`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 70% 30%;
  justify-content: center;
  background-color: rgba(000, 000, 000);

  @media (max-width: ${BREAKPOINT_TABLET}) {
    grid-template-columns: 100%;
    grid-template-rows: 40% 60%;
  }

  & img {
    align-self: flex-start;
    width: 6rem;

    @media (max-width: ${BREAKPOINT_MOBILE}) {
      width: 3rem;
    }
  }
`;

export const ContentWrapper = styled.section`
  width: 100%;
  color: ${vars.colors.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;
  gap: 1.5rem;

  & img {
    align-self: flex-start;
    width: 4rem;

    @media (max-width: ${BREAKPOINT_MOBILE}) {
      width: 3rem;
    }
  }

  & > div {
    width: 100%;
  }

  h1 {
    font-size: 1.2em !important;
    font-family: Primary, Arial, sans-serif;
    margin-bottom: 1em;
  }

  h2,
  h3 {
    font-size: 1.6em;
  }

  @media (max-width: 700px) {
    h1,
    h2 {
      font-size: 1.2em;
    }
  }
`;

type LanguagesDropdownProps = {
  isOpen: boolean;
};

export const LanguagesDropdown = styled.div<LanguagesDropdownProps>`
  position: relative;
  display: flex;
  width: 100%;
  color: black;
  font-size: 1rem;
  & button {
    display: flex;
    min-width: 16rem;
    background-color: white;
    padding: 0.2rem 0.5rem;
    font-family: ${vars.fontTertiary}, Arial, sans-serif;
    color: black;
    cursor: pointer;

    @media (max-width: ${vars.responsiveMin}px) {
      min-width: 15rem;
    }

    @media (max-width: 300px) {
      min-width: 10rem;
      font-size: 0.8rem;
    }

    & p {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & img {
        height: 24px;
        width: 24px;
        transform: ${({ isOpen }) =>
          isOpen ? "rotate(180deg)" : "rotate(0deg)"};
        transition: all 1s;
      }
    }
    &:hover {
      background-color: lightgray;
    }

    &:focus {
      outline: 2px solid var(--color-primary);
      border-radius: 5px;
    }
  }
  & > div div {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid lightgray;
    min-width: 16rem;
    z-index: 1;
    max-height: 30vh;
    overflow-y: scroll;

    @media (max-width: ${vars.responsiveMin}px) {
      max-height: 20vh;
      min-width: 15rem;
    }

    @media (max-width: 300px) {
      min-width: 10rem;
    }

    & a {
      color: black;
      font-family: ${vars.fontTertiary}, Arial, sans-serif;
      padding: 0.2rem 0.5rem;
      text-decoration: none;
      display: block;
      font-size: 1rem;
      cursor: pointer;
      &:hover {
        background-color: gray;
      }
      &:focus {
        outline: 2px solid var(--color-primary);
      }
    }
  }
`;

type ImageWrapperProps = {
  backgroundImage?: string;
};

export const ImageWrapper = styled.div<ImageWrapperProps>`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
`;
