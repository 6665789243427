import styled, { css } from "styled-components";
import { BREAKPOINT_MOBILE } from "../../styles/breakpoints";

export const TextImageLayoutWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px),
    (max-width: ${BREAKPOINT_MOBILE}) {
    overflow-y: auto;
  }
`;

type Props = {
  browser: string;
};

export const TextImageContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem;
  margin: ${({ browser }) => (browser === "ie" ? 0 : "auto")};

  @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
    padding: 0.5rem;
  }

  @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
    padding: 0.5rem;
  }
`;

type ItemsContainerProps = {
  imageVariant?: number;
  isTextOnRight: boolean;
  elShape: boolean;
  isBottom: boolean;
};

export const ItemsContainer = styled.div<ItemsContainerProps>`
  display: grid;
  grid-template-columns: 1fr 30%;
  grid-template-areas: "i1 i2";
  gap: 10px;
  height: 100%;
  width: 100%;

  @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
    gap: 5px;
    grid-template-columns: 100%;
    grid-template-rows: 70% 1fr; // check
    grid-template-areas: "i2" "i1";
  }

  @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 450px) {
    grid-template-columns: 1fr 30%;
    grid-template-rows: 100%;
    gap: 10px;
    grid-template-areas: "i1 i2";
  }

  ${({ imageVariant, isTextOnRight, elShape, isBottom }) => {
    if (imageVariant === 1 && isTextOnRight) {
      return css`
        grid-template-columns: 30% 1fr;
        grid-template-areas: "i2 i1";

        @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
          grid-template-columns: 100%;
          grid-template-areas: "i2" "i1";
        }

        @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 450px) {
          grid-template-columns: 30% 1fr;
          grid-template-rows: 100%;
          gap: 10px;
          grid-template-areas: "i2 i1";
        }
      `;
    }
    // 2 small images, text on left
    if (imageVariant === 2 && !isTextOnRight && !elShape && !isBottom) {
      return css`
        grid-template-columns: 1fr 30%;
        grid-template-rows: repeat(2, 100% / 2);
        grid-template-areas:
          "i1 i2"
          "i1 i3";

        @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
          grid-template-columns: 100%;
          grid-template-rows: 40% 40% 1fr;
          grid-template-areas: "i2" "i3" "i1";
        }

        @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
          grid-template-columns: repeat(2, 50%);
          grid-template-rows: repeat(2, 50%);
          grid-template-areas: "i2 i3" "i1 i1";
          overflow: hidden;
        }
      `;
    }
    // 2 small images, text on right
    if (imageVariant === 2 && isTextOnRight && !elShape && !isBottom) {
      return css`
        grid-template-columns: 30% 1fr;
        grid-template-rows: repeat(2, 100% / 2);
        grid-template-areas:
          "i2 i1"
          "i3 i1";

        @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
          grid-template-columns: 100%;
          grid-template-rows: 40% 40% 1fr;
          grid-template-areas: "i2" "i3" "i1";
        }

        @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
          grid-template-columns: repeat(2, 50%);
          grid-template-rows: repeat(2, 50%);
          grid-template-areas: "i2 i3" "i1 i1";
          overflow: hidden;
        }
      `;
    }
    // 2 images in L shape with text on left
    if (imageVariant === 2 && !isTextOnRight && elShape && !isBottom) {
      return css`
        grid-template-columns: 1fr 30%;
        grid-template-rows: repeat(2, 50%);
        grid-template-areas:
          "i1 i2"
          "i3 i2";

        @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
          grid-template-columns: 100%;
          grid-template-rows: 40% 40% 1fr;
          grid-template-areas: "i2" "i3" "i1";
        }

        @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
          grid-template-columns: repeat(2, 50%);
          grid-template-rows: repeat(2, 50%);
          grid-template-areas: "i2 i3" "i1 i1";
          overflow: hidden;
        }
      `;
    }
    // 2 images in L shape with text on right
    if (imageVariant === 2 && isTextOnRight && elShape && !isBottom) {
      return css`
        grid-template-columns: 30% 1fr;
        grid-template-rows: repeat(2, 50%);
        grid-template-areas:
          "i2 i1"
          "i2 i3";

        @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
          grid-template-columns: 100%;
          grid-template-rows: 40% 40% 1fr;
          grid-template-areas: "i2" "i3" "i1";
        }

        @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
          grid-template-columns: repeat(2, 50%);
          grid-template-rows: repeat(2, 50%);
          grid-template-areas: "i2 i3" "i1 i1";
          overflow: hidden;
        }
      `;
    }
    // 2 images in L shape with text on left and in the bottom
    if (imageVariant === 2 && !isTextOnRight && elShape && isBottom) {
      return css`
        grid-template-columns: 1fr 30%;
        grid-template-rows: repeat(2, 50%);
        grid-template-areas:
          "i3 i2"
          "i1 i2";

        @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
          grid-template-columns: 100%;
          grid-template-rows: 40% 40% 1fr;
          grid-template-areas: "i2" "i3" "i1";
        }

        @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
          grid-template-columns: repeat(2, 50%);
          grid-template-rows: repeat(2, 50%);
          grid-template-areas: "i2 i3" "i1 i1";
          overflow: hidden;
        }
      `;
    }
    // 2 images in L shape with text on right and in the bottom
    if (imageVariant === 2 && isTextOnRight && elShape && isBottom) {
      return css`
        grid-template-columns: 30% 1fr;
        grid-template-rows: repeat(2, 50%);
        grid-template-areas:
          "i2 i3"
          "i2 i1";

        @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
          grid-template-columns: 100%;
          grid-template-rows: 40% 40% 1fr;
          grid-template-areas: "i2" "i3" "i1";
        }

        @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
          grid-template-columns: repeat(2, 50%);
          grid-template-rows: repeat(2, 50%);
          grid-template-areas: "i2 i3" "i1 i1";
          overflow: hidden;
        }
      `;
    }
    if (imageVariant === 3 && !isTextOnRight && elShape && !isBottom) {
      return css`
        grid-template-columns: 1fr 30%;
        grid-template-rows: repeat(2, 50%);
        grid-template-areas:
          "i1 i2"
          "i3 i4";

        @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
          grid-template-columns: 100%;
          grid-template-rows: repeat(3, 30%) 1fr;
          grid-template-areas: "i2" "i3" "i4" "i1";
        }

        @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
          grid-template-columns: repeat(3, 33.333%);
          grid-template-rows: repeat(2, 50%);
          grid-template-areas: "i2 i3 i4" "i1 i1 i1";
          overflow: hidden;
        }
      `;
    }
    if (imageVariant === 3 && isTextOnRight && elShape && !isBottom) {
      return css`
        grid-template-columns: 30% 1fr;
        grid-template-rows: repeat(2, 50%);
        grid-template-areas:
          "i2 i1"
          "i3 i4";

        @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
          grid-template-columns: 100%;
          grid-template-rows: repeat(3, 30%) 1fr;
          grid-template-areas: "i2" "i3" "i4" "i1";
        }

        @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
          grid-template-columns: repeat(3, 33.333%);
          grid-template-rows: repeat(2, 50%);
          grid-template-areas: "i2 i3 i4" "i1 i1 i1";
          overflow: hidden;
        }
      `;
    }
    if (imageVariant === 3 && !isTextOnRight && elShape && isBottom) {
      return css`
        grid-template-columns: 1fr 30%;
        grid-template-rows: repeat(2, 50%);
        grid-template-areas:
          "i3 i2"
          "i1 i4";

        @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
          grid-template-columns: 100%;
          grid-template-rows: repeat(3, 30%) 1fr;
          grid-template-areas: "i2" "i3" "i4" "i1";
        }

        @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
          grid-template-columns: repeat(3, 33.333%);
          grid-template-rows: repeat(2, 50%);
          grid-template-areas: "i2 i3 i4" "i1 i1 i1";
          overflow: hidden;
        }
      `;
    }
    if (imageVariant === 3 && isTextOnRight && elShape && isBottom) {
      return css`
        grid-template-columns: 30% 1fr;
        grid-template-rows: repeat(2, 50%);
        grid-template-areas:
          "i3 i2"
          "i4 i1";

        @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
          grid-template-columns: 100%;
          grid-template-rows: repeat(3, 30%) 1fr;
          grid-template-areas: "i2" "i3" "i4" "i1";
        }

        @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
          grid-template-columns: repeat(3, 33.333%);
          grid-template-rows: repeat(2, 50%);
          grid-template-areas: "i2 i3 i4" "i1 i1 i1";
          overflow: hidden;
        }
      `;
    }
  }};
`;

type ItemContainerProps = {
  gridArea: string;
  imageVariant: number;
};

export const ItemContainer = styled.div<ItemContainerProps>`
  align-self: center;
  padding: 2rem;
  height: 65%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 1.5rem;
  grid-area: ${({ gridArea }) => gridArea && gridArea};

  @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
    padding: 2rem 0.5rem;
    gap: 0;
  }

  @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
    padding: 0rem;
    gap: 0.2rem;
    width: 100%;
    font-size: 0.8rem;
    height: 80%;
    justify-content: space-around;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 97%;

  @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
    gap: 0.2rem;
  }

  @media screen and (orientation: landscape) and (max-width: 821px) and (max-height: 600px) {
    gap: 10px;

    & > div:first-child {
      font-size: 1.2em;
    }
  }
`;

type IntroBannerProps = {
  backgroundImage: string;
  imageVariant: number;
  gridArea?: string;
};

export const TextImageBanner = styled.div<IntroBannerProps>`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;

  ${({ imageVariant, gridArea }) => {
    if (imageVariant === 2 || imageVariant === 3) {
      return css`
        grid-area: ${gridArea};
      `;
    }
  }};
`;
