import { TreeNode } from "./types";
import { fluidRange } from "polished";
import vars from "./styles/vars";
import { useApp, UserAnswerType } from "./state/app";

interface ResponsiveUnitProps {
  size: number;
  prop: string;
  ratio?: number;
}

export function responsiveUnit({ size, prop, ratio = 1 }: ResponsiveUnitProps) {
  return fluidRange(
    { toSize: `${size}px`, fromSize: `${size * ratio}px`, prop },
    `${vars.responsiveMin}px`,
    `${vars.responsiveMax}px`,
  );
}

type OrderedScenes = {
  scenes: { [sceneId: string]: number };
  maxIndex: number;
  lastSceneIds: string[];
};
const scenes: { [sceneId: string]: number } = {};
let maxIndex = 0;
const lastSceneIds: string[] = [];

export function getOrderedScenes(node: TreeNode): OrderedScenes {
  scenes[node.sceneId] = node.index;
  maxIndex = Math.max(maxIndex, node.index);

  if (node.componentId === "debrief" && !lastSceneIds.includes(node.sceneId)) {
    lastSceneIds.push(node.sceneId);
  }

  if (node.type !== "reference") {
    node.children.forEach((child) => {
      maxIndex = Math.max(maxIndex, child.index);
      getOrderedScenes(child);
    });
  }

  return { scenes, maxIndex, lastSceneIds };
}

export const updateUserAnswers = (answer: UserAnswerType) => {
  const { userAnswers, setUserAnswers } = useApp.getState();
  const index = userAnswers.findIndex((userAnswer) => {
    return (
      userAnswer.sceneId === answer.sceneId &&
      userAnswer.debriefIndex === answer.debriefIndex
    );
  });
  if (index === -1) {
    setUserAnswers([...userAnswers, answer]);
  } else {
    const newUserAnswers = [...userAnswers];
    newUserAnswers[index] = answer;
    setUserAnswers(newUserAnswers);
  }
};
