import styled from "styled-components";
import {
  BREAKPOINT_HORIZONTAL,
  BREAKPOINT_MOBILE,
} from "../../styles/breakpoints";

export const IntroLayoutWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
type Props = {
  browser: string;
};

export const IntroContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 1rem;
  margin: ${({ browser }) => (browser === "ie" ? 0 : "auto")};
`;

export const ItemsContainer = styled.div`
  display: grid;
  grid-template-rows: 55% 40%;
  gap: 1rem;
  height: 100%;
  width: 100%;
  overflow: hidden;

  @media screen and (max-height: ${BREAKPOINT_HORIZONTAL}) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    grid-template-areas: "text img";
  }

  @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
    grid-template-columns: 100%;
    grid-template-rows: 40% 60%;
    grid-template-areas:
      "img"
      "text";
    gap: 0;
    overflow-y: auto;
  }

  @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    grid-template-areas: "text img";
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "img1 img2"
    "img1 img3";
  gap: 10px;

  & div:last-child {
    background-position: top;
  }

  @media screen and (max-height: ${BREAKPOINT_HORIZONTAL}),
    (max-width: ${BREAKPOINT_MOBILE}) {
    grid-area: img;
  }

  @media screen and (max-width: 800px) {
    & div:nth-child(2) {
      background-position: top;
    }
  }
`;

type IntroBannerProps = {
  backgroundImage: string;
  grid: string;
};

export const IntroImage = styled.div<IntroBannerProps>`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  grid-area: ${({ grid }) => grid && grid};
`;

export const IntroTextWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 50% 48%;
  padding-top: 1rem;
  gap: 10px;

  @media screen and (max-height: 630px) {
    padding-top: 0;
  }

  @media screen and (max-height: ${BREAKPOINT_HORIZONTAL}),
    (max-width: ${BREAKPOINT_MOBILE}) {
    grid-area: text;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
    gap: 0rem;
  }

  @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
    gap: 0;
    padding-top: 0;
    height: 100%;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;

  @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
    gap: 0;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogoBox = styled.div`
  & img {
    width: 18rem;
    height: auto;
  }
`;

export const TextBoxWrapper = styled.div`
  color: #fff;
  font-size: 2.2em;
  background-color: var(--color-primary);
  padding: 0.5rem 1rem;

  @media screen and (max-width: 1150px) {
    font-size: 2em;
    padding: 0.5rem 0.5rem;
  }

  @media screen and (max-width: 950px) {
    font-size: 1.8em;
    padding: 0.5rem 0.8rem;
  }

  @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
    font-size: 1.2em;
    padding: 0.5rem 0.8rem;
  }

  @media screen and (max-height: ${BREAKPOINT_HORIZONTAL}) {
    font-size: 1.2em;
  }

  @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
    font-size: 1em;
  }

  @media screen and (max-width: 360px) {
    font-size: 0.9em;
  }
`;

export const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-height: ${BREAKPOINT_HORIZONTAL}),
    (max-width: ${BREAKPOINT_MOBILE}) {
    padding: 1rem 0.5rem;
    gap: 1rem;
  }

  & div:first-child {
    align-self: flex-start;

    @media screen and (max-height: ${BREAKPOINT_HORIZONTAL}),
      (max-width: ${BREAKPOINT_MOBILE}) {
      align-self: flex-end;
    }
  }

  & button:last-child {
    align-self: flex-end;

    @media screen and (max-height: ${BREAKPOINT_HORIZONTAL}),
      (max-width: ${BREAKPOINT_MOBILE}) {
      align-self: flex-start;
      padding: 0.2rem 1.5rem;
    }

    @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
    padding: 0.1rem 2rem;
  }
  }
`;