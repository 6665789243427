import React from "react";
import {
  TextLinkContainer,
  TextLinkWrapper,
  TextWrapper,
  TitleWrapper,
} from "./styles";
import Html from "../html/html";
import { Answer, LocaleValue } from "../../types";
import { useApp } from "../../state/app";
import Animate from "../animate/animate";
import { MODE } from "../../config/consts";
import scorm from "../../services/scorm";
import HtmlDebrief from "../htmlDebrief/htmlDebrief";

type Props = {
  sceneId: string;
  image?: string;
  title?: LocaleValue;
  description: LocaleValue;
  descriptionColor?: string;
  titleColor?: string;
  nextButtonBackground?: string;
  nextButtonColor?: string;
  answers: Answer[];
  backgroundColor?: string;
  lastScene?: boolean;
};

export default function TextLink({
  image = "./assets/adecco-logo.svg",
  title,
  description,
  descriptionColor = "#fff",
  titleColor = "#fff",
  backgroundColor = "#000",
  lastScene = false,
}: Props) {
  const { language, appTitle } =
    useApp();

  React.useMemo(() => {
    document.title = appTitle ?? "Continue Screen";
  }, [appTitle]);
  const focusRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    focusRef.current?.focus();
  }, []);

  React.useEffect(() => {
    if (!MODE.startsWith("scorm")) return;
    if (!scorm.isConnected()) return;
    if (lastScene) {
      scorm.complete();
    }
  }, [lastScene]);

  return (
    <TextLinkWrapper style={{ backgroundColor }} ref={focusRef}>
      <Animate />
      <TextLinkContainer>
        <img src={image}/>
        <TextWrapper>
          {title && Object.values(title)[0] && (
            <TitleWrapper>
              <HtmlDebrief
                html={`<h3>${title?.[language] ?? ""}</h3>`}
                style={{ color: titleColor, fontSize: "3rem" }}
              />
            </TitleWrapper>
          )}
        </TextWrapper>
      </TextLinkContainer>
    </TextLinkWrapper>
  );
}
