import React from "react";
import { LocaleWrapper, ImageWrapper, ContentWrapper, LanguagesDropdown } from "./styles";
import { useApp } from "../../state/app";
import Button from "../button/button";
import useAppLogic from "../../hooks/use-app-logic";

type Props = {
  locales: { label: string; value: string }[];
  nextScene?: string;
};

export default function Locale({ locales, nextScene }: Props) {
  const { appText, language } = useAppLogic();
  const [isVisible, setIsVisible] = React.useState(false);
  const [locale, setLocale] = React.useState("");

  const handleChange = (locale: string) => {
    setLocale(locale);
  };
  return (
    <LocaleWrapper>
      <ImageWrapper backgroundImage="./assets/langselimg.png"></ImageWrapper>
      <ContentWrapper>
       <img src="./assets/globe-icon.svg" alt="" />
      <LanguagesDropdown isOpen={isVisible}>
        <div>
          <button
            type="button"
            aria-label={
              appText?.selectLanguage?.[language] ??
              "Please select your language."
            }
            onClick={() => {
              setIsVisible(!isVisible);
            }}
          >
            <p>
              <span>{locale === "" ? "Choose from the list..." : locale}</span>{" "}
              <img src="./assets/chevron.svg" alt="Arrow down"/>
            </p>
          </button>
          {isVisible === false ? (
            ""
          ) : (
            <div>
              {locales.map((locale) => (
                <a
                  key={locale.label}
                  onClick={() => {
                    setIsVisible(false);
                    const {setLanguage } =
                      useApp.getState();
                    setLanguage(locale.value);
                    handleChange(locale.label);
                  }}
                  onKeyPress={() => {
                    setIsVisible(false);
                    const {setLanguage } =
                      useApp.getState();
                    setLanguage(locale.value);
                    handleChange(locale.label);
                  }}
                  tabIndex={0}
                >
                  {locale.label}
                </a>
              ))}
            </div>
          )}
        </div>
      </LanguagesDropdown>
      <Button
        style={{
          opacity: locale === "" ? "0" : "1",
          pointerEvents: locale === "" ? "none" : "all",
          visibility: locale === "" ? "hidden" : "visible",
          color: "black",
          padding: "0.2rem 1rem"
        }}
        backgroundColor={"white"}
        link={nextScene}
        onClick={() => { const { setLanguageSelected } =
        useApp.getState(); setLanguageSelected(true)}}
      >
        {appText?.continue?.[language] ?? "Continue"}
      </Button>
      </ContentWrapper>
    </LocaleWrapper>
  );
}
