import styled from "styled-components";
import { BREAKPOINT_MOBILE } from "../../styles/breakpoints";
import vars from "../../styles/vars";

type Props = {
  color?: string;
  backgroundColor?: string;
  variant?: string | null;
};

export const ButtonWrapper = styled.button<Props>`
  padding: 0.3em 2.5em;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border-radius: 2rem;
  font-family: ${vars.fontTertiary};
  display: inline-block;
  cursor: pointer;
  position: relative;
  letter-spacing: 0.1rem;
  border: 3px solid var(--color-primary);

  &:hover {
    opacity: 0.8;
  }

  ${({ variant }) => {
    if (variant === "debrief")
      return `
      max-width: 600px;
      display: inline-block;
      margin: 1rem auto;
      border: 1px solid ${vars.colors.gray100}
      background: ${vars.colors.white};
      border: none;
      box-sizing: border-box;
      font-weight: bold;
      font-family: ${vars.fontPrimary};
      line-height: 1;
      padding-bottom: 10px;
    `;
  }};

  @media (max-width: ${vars.responsiveMin}px) {
    margin-left: 0 !important;
    font-size: 0.9em;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}) {
    padding: 0.3em 1em;
  }
`;
