import React from "react";
import {
  ContentContainer,
  ItemContainer,
  ItemsContainer,
  TextImageBanner,
  TextImageContainer,
  TextImageLayoutWrapper,
} from "./styles";
import HtmlHeader from "../htmlHeader/htmlHeader";
import { useApp } from "../../state/app";
import {
  Answer,
  DynamicLink as DynamicLinkType,
  LocaleValue,
} from "../../types";
import Animate from "../animate/animate";
import Scene from "../scene/scene";
import { MODE } from "../../config/consts";
import scorm from "../../services/scorm";
import vars from "../../styles/vars";
import Button from "../button/button";
import DynamicLink from "../dynamic-link/dynamic-link";
import HtmlContinueScreen from "./HtmlContinueScreen/htmlContinueScreen";
import useAppLogic from "../../hooks/use-app-logic";

const EMPTY_ARRAY: DynamicLinkType[] = [];

type Props = {
  image1: string;
  image2: string;
  image3: string;
  title: LocaleValue;
  primaryColor: string;
  header: LocaleValue;
  description: LocaleValue;
  startButtonText: string;
  startButtonBackground: string;
  backgroundColor: string;
  startButtonColor?: string;
  answers: Answer[];
  dynamicLinks: DynamicLinkType[];
  lastScene?: boolean;
  isTextOnRight: boolean;
  isBottom: boolean;
  elShape: boolean;
};

export default function TextImage({
  image1,
  image2,
  image3,
  title,
  backgroundColor = "#000",
  header,
  description,
  dynamicLinks = EMPTY_ARRAY,
  startButtonBackground,
  startButtonColor = "#000",
  answers,
  lastScene = false,
  isTextOnRight,
  elShape,
  isBottom,
}: Props) {
  const browser = useApp((state) => state.browser);
  const language = useApp((state) => state.language);
  const imagesArr = [image1, image2, image3];

  const { appTitle, appText } = useApp();

  React.useMemo(() => {
    document.title = appTitle ?? "Continue Screen";
  }, [appTitle]);

  let images: string[] = [];

  imagesArr.forEach((image) => image !== "" && images.push(image));

  const focusRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    focusRef.current?.focus();
  }, []);

  React.useEffect(() => {
    if (!MODE.startsWith("scorm")) return;
    if (!scorm.isConnected()) return;
    if (lastScene) {
      scorm.complete();
    }
  }, [lastScene]);

  const imageVariant = images?.length;

  return (
    <Scene>
      <TextImageLayoutWrapper
        style={{
          backgroundColor,
        }}
      >
        <Animate />
        <TextImageContainer browser={browser}>
          <ItemsContainer
            imageVariant={imageVariant}
            isTextOnRight={isTextOnRight}
            elShape={elShape}
            isBottom={isBottom}
          >
            <ItemContainer gridArea={`i1`} imageVariant={imageVariant}>
              <ContentContainer>
                <HtmlHeader html={header?.[language]} />
                <HtmlContinueScreen
                  html={description?.[language]}
                  style={{ color: "#fff", fontFamily: `${vars.fontTertiary}` }}
                  variant={imageVariant}
                />
              </ContentContainer>
              {dynamicLinks.length === 0 && (
                <>
                  {answers.map((answer, index) => {
                    return (
                      <Button
                        backgroundColor={startButtonBackground}
                        color={startButtonColor}
                        link={answer.sceneId}
                        key={index}
                      >
                        {answer?.text?.[language] ??
                          appText?.continue?.[language] ??
                          "Start"}
                      </Button>
                    );
                  })}
                </>
              )}
              <DynamicLink
                dynamicLinks={dynamicLinks}
                startButtonBackground={startButtonBackground}
                startButtonColor={startButtonColor}
              />
            </ItemContainer>
            {images?.length !== 0 &&
              images.map((image, index) => (
                <TextImageBanner
                  key={image}
                  backgroundImage={image}
                  imageVariant={imageVariant}
                  gridArea={`i${index + 2}`}
                />
              ))}
          </ItemsContainer>
        </TextImageContainer>
      </TextImageLayoutWrapper>
    </Scene>
  );
}
