import React from "react";
import vars from "../../styles/vars";
import { LocaleValue } from "../../types";
import Button from "../button/button";
import { SceneDebriefContainer, SceneDebriefContent, Image } from "./styles";
import Html from "../html/html";
import HtmlDebrief from "../htmlDebrief/htmlDebrief";

interface SceneDebrief {
  image: string;
  userAnswer: LocaleValue | string;
  headline: string;
  sceneId: string;
  index: number;
}

export const SceneDebrief = ({
  image,
  userAnswer,
  headline,
  sceneId,
  index
}: SceneDebrief) => {
  return (
    <SceneDebriefContainer index={index}>
      <div>
        <Image src={image} />
        <SceneDebriefContent>
          <h2
            dangerouslySetInnerHTML={{ __html: headline }}
            style={{ fontFamily: `${vars.fontSecondary}`, color: "var(--color-primary)" }}
          />
          <HtmlDebrief
            html={userAnswer}
            style={{ fontFamily: `${vars.fontTertiary}` }}
          />
          {/* <Button
            backgroundColor={"white"}
            color={"black"}
            style={{ border: "1px solid var(--color-primary)", padding: "0.3em 1.5em "}}
            link={sceneId}
          >
            Replay
          </Button> */}
        </SceneDebriefContent>
      </div>
    </SceneDebriefContainer>
  );
};
