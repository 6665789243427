import styled, { css } from "styled-components";
import { BREAKPOINT_MOBILE } from "../../../styles/breakpoints";

type HtmlWrapperProps = {
  variant?: number;
};

export const HtmlWrapper = styled.div<HtmlWrapperProps>`
  margin: 0;
  padding: 0;
  font-size: 1em;
  overflow-y: auto;
  max-height: max-content;

  & p {
    padding-bottom: 1rem;
    margin-right: 15px;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  @media screen and (max-height: 650px) and (max-width: 600px) {
    & p {
      font-size: 0.8em;
    }
  }

  @media screen and (min-height: 200px) and (min-width: ${BREAKPOINT_MOBILE}) {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 200px;

    ${({ variant }) => {
      if (variant === 1) {
        return css`
          max-height: 150px;
        `;
      }
      if (variant === 2) {
        return css`
          max-height: 120px;
        `;
      }
      if (variant === 3) {
        return css`
          max-height: 120px;
        `;
      }
    }}
  } 

  @media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 600px) {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    ${({ variant }) => {
      if (variant === 1) {
        return css`
          max-height: 100px;
        `;
      }
      if (variant === 2) {
        return css`
          max-height: 100px;
        `;
      }
      if (variant === 3) {
        return css`
          max-height: 80px;
        `;
      }
    }}
  } 
`;
