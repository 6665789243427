import styled from "styled-components";
import vars from "../../styles/vars";
import { responsiveUnit } from "../../utils";

type WrapperProps = {};
export const Wrapper = styled.div<WrapperProps>`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  padding-bottom: 40px;

  .bg-gray {
    background-color: ${vars.colors.gray100};
  }

  h1 {
    margin-top: 1rem !important;
  }

  h1,
  h2,
  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.3rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.3;
  }

  p ~ ul {
    margin-top: 0 !important;
  }

  li {
    font-size: 1rem;
    line-height: 1.1;
  }

  ul li::marker {
    color: var(--color-primary);
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin-bottom: 1.2rem;
    margin-top: 0;
  }

  h1 {
    font-family: ${vars.fontTertiary};
    color: ${vars.colors.black800};
    font-weight: normal;
  }

  h2,
  h3 {
    font-family: ${vars.fontTertiary};
    color: ${vars.colors.black800};
    font-weight: 800;
  }

  h4 {
    font-family: ${vars.fontTertiary};
    color: ${vars.colors.black800};
  }

  p {
    font-family: ${vars.fontPrimary};
    font-weight: 400;
  }

  .section {
    &--visible {
      display: block;
      height: 100%;
    }

    &--hidden {
      display: none;
    }
  }

  .row {
    display: grid;
    width: 100%;
    &--1fr-2fr {
      @media screen and (min-width: 800px) {
        grid-template-columns: 1fr 2.4fr;
      }
    }
  }

  .col {
    position: relative;
  }
`;

type IntroProps = {};
export const Intro = styled.div<IntroProps>`
  padding-top: 1rem;

  h1 {
    font-size: 3.8em;
    font-weight: bold;
    font-family: ${vars.fontPrimary};
  }

  p {
    strong {
      display: block;
    }

    font-family: ${vars.fontSecondary};
    color: ${vars.colors.black};
  }

  ul.no-styling {
    padding-left: 20px;
    margin-top: -20px;

    li {
      margin-left: 1.1rem;
      font-family: ${vars.fontSecondary};
    }
  }

  ul:not(.no-styling) {
    list-style-type: none;
    position: relative;
    padding: 0;
    counter-reset: listStyle;

    li {
      counter-increment: listStyle;
      position: relative;
      font-family: ${vars.fontSecondary};
      margin: 36px 0;
      padding: 0.5rem;
      padding-left: 62px;
      display: flex;
      align-items: center;
      font-weight: 300;

      &:before {
        content: "X";
        line-height: 1;
        text-transform: uppercase;
        position: absolute;
        transform-origin: center;
        border-radius: 50%;
        background-color: var(--color-primary);
        color: ${vars.colors.black};
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        left: 0;
        ${responsiveUnit({ size: 26, prop: "font-size", ratio: 0.8 })}
        ${responsiveUnit({ size: 48, prop: "width", ratio: 0.8 })}
        ${responsiveUnit({ size: 48, prop: "height", ratio: 0.8 })}
      }
    }
  }
`;

type ListItemProps = {};
export const ListItemWrapper = styled.ul<ListItemProps>``;

type BannerProps = {
  imgSrc: string;
  variant: "primary" | "secondary";
};

export const BannerWrapper = styled.div<BannerProps>`
  height: ${({ variant }) => (variant === "primary" ? "320px" : "auto")};
  width: 100%;
  padding: 5em 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: ${vars.colors.white};
  background-image: ${({ imgSrc, variant }) =>
    variant === "primary"
      ? `url(${imgSrc})`
      : `linear-gradient(90deg, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0) 92.68%), url(${imgSrc})`};

  @media (min-width: 650px) {
    background-position: 50% 20%;
  }
`;

export const BannerText = styled.div`
color: #fff;
width: 40%;
margin-left: 7rem;

& h2 {
  color: #fff;
  font-family: ${vars.fontTertiary};
}

& p {
  font-family: ${vars.fontSecondary};
}`;

type DeepDiveSectionProps = {};

export const DeepDiveSectionWrapper = styled.div<DeepDiveSectionProps>`
  display: grid;
  grid-template-columns: 1fr;
  font-family: ${vars.fontPrimary};
  row-gap: 2em;
  color: ${vars.colors.black750};
  @media screen and (min-width: 802px) {
    grid-template-columns: 1fr 1fr;
    grid-row: 1 / 2;
    column-gap: 2em;
  }

  img {
    margin: 0;
    vertical-align: middle;
  }
  div {
    @media screen and (min-width: 802px) {
      grid-column-start: 1;
      grid-column-end: span 2;
    }
  }
`;

type ContainerProps = {
  variant?: string;
};

export const Container = styled.div<ContainerProps>`
  max-width: 1210px;
  margin: 0 auto;
  padding: 0 1em;

  ${({ variant }) => {
    if (variant === "center") {
      return `
        text-align: center;
      `;
    }
    return ``;
  }}
`;

type DebriefAtRiskProps = {
  bgColor?: string;
};

export const DebriefAtRiskWrapper = styled.div<DebriefAtRiskProps>`
  font-family: ${vars.fontPrimary};
  padding: 1rem 0;
  background-color: ${({ bgColor }) => bgColor && bgColor};

  .img-card {
    max-width: 285px;
    width: 100%;
    height: auto;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  .img-card-columns {
    display: grid;
    grid-template-columns: 50% 50%;

    img {
      width: 100%;
      height: 100%;
      padding-right: 1rem;
    }
  }

  .group-quote {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-20%);
    z-index: 1;
    .quote {
      &:first-child {
        margin-right: 10px;
      }
    }

    img {
      width: 44px;
      opacity: 0.8;
    }
  }

  & h3 {
    font-family: ${vars.fontTertiary};
    color: ${vars.colors.green};
    margin-bottom: 50px;
  }

  p {
    margin-left: 8px;
    margin-bottom: 0.8em;
    line-height: 1.5;
  }
`;

export const TitleWrapper = styled.div``;

export const UserAnswerWrapper = styled.div`
  padding: 1rem 0;
`;

export const UserContainer = styled.div`
  display: flex;
  justify-content: center;

`;

export const AnswerText = styled.div<{ client?: string }>`
  p {
    display: inline-block;
    color: ${vars.colors.black};
    margin-bottom: 0 !important;
  }

  ol:not(.no-styling),
  ul:not(.no-styling) {
    list-style-type: none;
    position: relative;
    padding: 0;
    counter-reset: listStyle;

    li {
      counter-increment: listStyle;
      position: relative;
      font-family: ${vars.fontPrimary};
      margin: 36px 0;
      padding-left: 62px;
      display: flex;
      align-items: center;
      font-weight: 300;

      &:before {
        content: counter(listStyle, lower-alpha);
        line-height: 1;
        text-transform: uppercase;
        position: absolute;
        transform-origin: center;
        border-radius: 50%;
        background-color: ${({ client }) =>
          client === "HSBC" ? "#DB0011" : "#d93954"};
        color: ${vars.colors.white};
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        left: 0;
        padding-bottom: 0.15em;
        font-weight: bold;
        ${responsiveUnit({ size: 26, prop: "font-size", ratio: 0.8 })}
        ${responsiveUnit({ size: 48, prop: "width", ratio: 0.7 })}
        ${responsiveUnit({ size: 48, prop: "height", ratio: 0.7 })}
      }
    }
  }
`;

export const ReplaySceneWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 800px) {
    justify-content: flex-start;
  }
`;

export const ReplayScene = styled.div`
  position: relative;
  z-index: 1;
  width: 21rem;
  height: 13rem;

  @media (max-width: 450px) {
    width: 100%;
  }
`;

type SpacerProps = {
  backgroundImage: string;
};

export const Spacer = styled.div<SpacerProps>`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 21rem;
  height: 13rem;

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const ButtonDivider = styled.span`
  margin-right: 1rem;
`;

export const ReplayButton = styled.button`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.6rem 1.3rem;
  background-color: rgba(255, 255, 255, 0.8);
  color: ${vars.colors.black};
  font-family: ${vars.fontPrimary};
  font-weight: bold;
  font-size: 1rem;

  & > span:first-child {
    padding-top: 0.1rem;
  }

  & > span:last-child {
    display: inline-block;
    background-image: url("assets/replay_icon.svg");
    height: 1rem;
    width: 1rem;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 0.7rem;
  }
`;

export const ButtonContainer = styled.div``;
