import styled from "styled-components";

export const HtmlWrapper = styled.div`
  margin: 0;
  padding: 0;
  font-size: 1em;
  overflow-y: auto;

  & p {
    padding-bottom: 1rem;
  }

  &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-primary);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

  & p {
    margin-right: 15px;
  }

  @media screen and (orientation: landscape) {
    max-height: 200px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  @media screen and (orientation: landscape) and (max-width: 900px){
    max-height: 150px;
  }
`;