import styled from "styled-components";
import { BREAKPOINT_MOBILE } from "../../styles/breakpoints";

export const HtmlWrapper = styled.div`
  margin: 0;
  padding: 0;
  font-size: 1em;
  overflow-y: hidden;

  & p {
    padding-bottom: 1rem;
  }

  &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-primary);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

  & p {
    margin-right: 15px;
  }

  @media screen and (max-height: 1000px) and (max-width: ${BREAKPOINT_MOBILE}) {
    max-height: 150px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  @media screen and (max-height: 600px) {
    max-height: 150px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  @media screen and (max-height: 520px) {
    max-height: 90px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  @media screen and (max-height: 668px) and (max-width: ${BREAKPOINT_MOBILE}) {
    max-height: 70px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
`;
