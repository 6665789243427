import styled from "styled-components";
import { BREAKPOINT_MOBILE } from "../../styles/breakpoints";

export const TextLinkWrapper = styled.div`
  height: 100%;
  background-color: black;
`;

export const TextLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  padding: 5rem;
  gap: 10rem;

  & > img {
    width: 500px;
    max-width: 500px;
  }

  @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
    padding: 2rem;
    gap: 2rem;
  }

  @media screen and (orientation: landscape) and (max-width: 915px) and (max-height: 600px) {
    padding: 2rem;
    gap: 2rem;
  }
`;

export const TextWrapper = styled.div`
  max-width: 920px;
`;

export const TitleWrapper = styled.div`
`;
