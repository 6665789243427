import styled, { css } from "styled-components";
import { BREAKPOINT_MOBILE } from "../../styles/breakpoints";
import vars from "../../styles/vars";

export const MiddleDebriefWrapper = styled.section`
  background-color: ${vars.colors.white};
  width: 100%;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-y: scroll;
`;

type BannerImageProps = {
  src?: string;
};

export const BannerImage = styled.div<BannerImageProps>`
  width: 100%;
  height: 30vh;

  background-image: ${({ src }) => src && `url(${src})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const BannerText = styled.div`
  background-color: var(--color-primary);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  text-align: center;

  @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-left: 0;
      padding-right: 0;
    }

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    & div {
      width: 70%;
    }
  }
`;

export const SceneDebriefWrapper = styled.div`
  & > div:nth-child(2n) {
    background-color: #f8f8f8;
  }
`;

type SceneDebriefContainerProps = {
  index: number;
};

export const SceneDebriefContainer = styled.div<SceneDebriefContainerProps>`
  & > div {
    width: 70%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: "image content";
    gap: 2rem;
    margin: 0 auto;
    padding: 2rem;

    @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
      width: 95%;
      grid-template-columns: 1fr;
      grid-template-rows: 200px 1fr;
      grid-template-areas:
        "image"
        "content";
      padding: 0.5rem;
      gap: 0.5rem;
      place-items: center;
    }

    ${({ index }) => {
      if (index % 2 === 0) {
        return css`
          grid-template-areas: "content image";
        `;
      }
    }};
  }
`;

type ImageProps = {
  src?: string;
};

export const Image = styled.div<ImageProps>`
  background-image: ${({ src }) => src && `url(${src})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 90%;
  grid-area: image;

  @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
      max-height: 200px;
  }
`;

export const SceneDebriefContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  grid-area: content;

  @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
      text-align: center;
      align-items: center;
  }
`;

export const ScoreContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  gap: 1rem;
  color: var(--color-primary);

  @media screen and (max-width: ${BREAKPOINT_MOBILE}) {
      text-align: center;
       & > div {
        display: flex;
        flex-direction: column;
       }
  }

  & p {
    font-family: ${vars.fontSecondary};
    width: 70%;
    text-align: center;
    line-height: 1.5rem;
  }
`;
